.red-segment {
  stroke: #e91e63;
}
.green-segment {
  stroke: #4caf50;
}
.yellow-segment {
  stroke: #fb8c00;
}
.speedometer-svg {
  overflow: visible;
  width: 100%;
  margin-block: 2em;
}
