.leaflet-div-icon {
    background-color : transparent;
    border : 0;
}

.rd3t-node {
    stroke: unset !important;
    stroke-width: unset !important;
}

.rd3t-leaf-node {
    stroke: unset !important;
    stroke-width: unset !important;
}

.rd3t-link {
    stroke : red !important;
}
::-webkit-scrollbar{
    width: 16px!important;
    height: 13px!important;
  }
  ::-webkit-scrollbar-track{
    background: hsla(0, 8%, 91%, 0.1);
  } 
  
  ::-webkit-scrollbar-thumb{
    background: rgba(238, 236, 236, 0.2);
  }