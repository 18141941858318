body {
    line-height: normal !important;
  }
  .rbc-header {
    color: white;
    background-color: #17405d;
    font-size: 14px !important;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .rbc-event-content {
    text-align: center;

  }
  
  .rbc-time-view .rbc-row {
    min-height: 0px;
  }
  
  .rbc-time-slot {
    z-index: 1;
    background-color: none;
  }
  
  .rbc-off-range-bg {
    background-color: rgb(123, 131, 142);
  }

  .rbc-off-range {
    color: white;
  }
  .rbc-agenda-table {
    color: white;
  }
  .rbc-today {
    background-color: #003554 !important;
    color: #fff !important;
  }
  .rbc-current > button {
    color: #fff !important;
  }
  .rbc-today {
    background-color: #003554 !important;
    color: #fff !important;
  }
  .rbc-event,
  .rbc-background-event:not(.rbc-today .rbc-time-slot) {
    z-index: 2;
    padding: 0px !important;
    border: none !important;
    background-color: #04a7aa75;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    height: 24px;
  }
  .rbc-event {
    background-color: brown !important;
    width: 70% !important;
    border-radius: 0px !important;
    text-align: center;
    border-radius: 5em !important;
  }
  
  .rbc-day-slot .rbc-time-slot {
    border: none !important;
  }
  
  .rbc-event-label {
    display: none !important;
  }
  .rbc-time-view .rbc-row {
    min-height: 0px !important;
  }
  
  .rbc-month-view {
    max-height: 400px !important;
  }
  
  .rbc-events-container {
    width: 100% !important;
  }
  
  .rbc-day-slot .rbc-time-slot:after {
    content: "";
    width: 100%;
    display: block;
  }
  
  .rbc-agenda-table thead tr {
    display: flex;
  }
  
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    font-size: 15px;
  }
  
  .rbc-show-more {
    background-color: transparent !important;
    font-size: 16px !important;
    color: #fff !important;
    font-size: 10px !important;
  
    font-weight: normal !important;
  }
  
  .rbc-row-segment {
    text-align: center !important;
    display: flex;
    justify-content: center;
  }
  
  .rbc-button-link {
    color: inherit;
  }

.rbc-overlay-header{
    font-size: medium;
    background-color: #17405d;
    color: white;
}
.rbc-overlay{
    background-color: #17405d !important;
    border: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 5px !important;
}

.rbc-overlay > .rbc-event{
    width:100% !important;
}

.rbc-toolbar button{
    color: #1A73E8 !important;
}

.rbc-day-bg{
  display: flex;
  align-items: center;
}