.first-tag-item,
.tag-item {
  border: 1.5px solid #ccc;
  border-radius: 1.5em;
  padding-block: 0.06em;
  padding-inline: 0.35em 1.3em;
  position: relative;
  color: lightgray;
  font-size: 0.85rem;
  box-shadow: 0 0.5px 0 #ccc, 0 1px 0 #c9c9c9, 0 1.5px 0 #bbb, 0 2px 0 #b9b9b9,
    0 2.5px 0 #aaa, 0 3px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 0.5px 3px rgba(0, 0, 0, 0.3), 0 1.5px 5px rgba(0, 0, 0, 0.2),
    0 2.5px 10px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.2),
    0 10px 20px rgba(0, 0, 0, 0.15);
}
.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}
.tag-item {
  margin-left: 0.05em;
}
.tag-remove {
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  border: none;
  background-color: transparent;
  color: #e91e63;
  cursor: pointer;
  font-size: 0.85rem;
}
.ReactTags__tagInputField {
  display: none;
}