.darkTheme {
  $ka-background-color: #202940;
  $ka-color: #fff;
  $ka-border-color: #1a2035;
  // $ka-cell-hover-background-color: transparentize(#1a2035, 0.8);
  $ka-color-base: #f5f0f0; 
  $ka-input-background-color: $ka-background-color;
  $ka-input-border-color: $ka-border-color;
  $ka-input-color: $ka-color-base;
  $ka-row-hover-background-color: transparentize(#cfc9c9, 0.9);
  $ka-thead-background-color: #1a2035;
  $ka-thead-color: #f90e0e;
  $ka-icon-group-arrow:#FAFAFA;
  $ka-icon-color:$ka-color-base;
    @import "ka-table/style.scss";
  }
.lightTheme {
  $ka-background-color: #FAFAFA;
  $ka-border-color: #f1f1f1;
  // $ka-cell-hover-background-color: transparentize(#1a2035, 0.8);
  $ka-color-base: #202940; 
  $ka-input-background-color: $ka-background-color;
  $ka-input-border-color: $ka-border-color;
  $ka-input-color: $ka-color-base;
  $ka-row-hover-background-color: transparentize(#cfc9c9, 0.9);
  $ka-thead-background-color: #FFFFFF;
  $ka-thead-color: #FAFAFA;
  $ka-icon-group-arrow:#FAFAFA;
  $ka-icon-color:$ka-color-base;
    @import "ka-table/style.scss";
  }