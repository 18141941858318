.btn{
    border: none;
    display: flex;
    color: white;
    background: #a2a8c5;
    padding: .5em;
    cursor: pointer;
}
.add{
    border-radius: 6px 0px 0px 6px;  
}
.minus{
    border-radius: 0px 6px 6px 0px;
}
.icon{
    transition: 300ms;
}
.icon:hover{
    transform: scale(1.2);
}
